* {
  padding: 0px;
  margin: 0px;
  text-align: center;
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.app {
  background: #fbfbfb;
  width: 90vw;
  margin: auto;
  display: grid;
  grid-template-areas:
    "menu menu"
    "main aside"
    "footer auto";

  grid-template-columns: 70% auto;
  grid-template-rows: auto auto 70vh;
}

.slides {
  position: relative;
}
.slides li button::before {
  background-color: black !important;
  color: black !important;
}

.slick-arrow {
  background-color: black;
  color: black !important;
}

.nightTheme,
.nightTheme * {
  background: rgb(32, 32, 32);
  color: #aaa;
}

.nightTheme #containerMain div,
.nightTheme #containerMain div * {
  background: rgb(43, 42, 42);
}

.nightTheme footer p,
footer * {
  background: rgb(27, 27, 27) !important;
}

.nightTheme .buttonMenu div,
.nightTheme .selected {
  background: rgba(46, 46, 46, 0.856);
  color: black;
}

.nightTheme li:hover {
  background: white !important;
  color: black !important;
}

.nightTheme button {
  color: white !important;
}

.nightTheme button:hover {
  color: white;
}

/* .nightTheme .card,.nightTheme .card p,.nightTheme .card h5{
    background: grey !important;
    color: white !important;
} */

.selected .nightTheme li {
  background: rgb(231, 228, 228) !important;
  color: black;
}

#nightThemeButton {
  color: black;
  z-index: 99;
  width: 4vw;
  height: 8vh;
  position: fixed;
  top: 0px;
  right: 0px;
}

audio {
  margin-left: 2vw;
  z-index: 102;
  background: transparent;
  filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
}

.card {
  background-color: white;
  width: 70% !important;
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1em 0px;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2vh auto !important;
  margin: auto;
  height: 80vh;
}

.card h5 {
  font-size: 1.6em;
  margin: 4vh auto;
}

.card:hover {
  box-shadow: 0 10px 18px 0 rgba(0, 0, 0, 0.2);
}

/* .card img {
  transform: scale(2);
} */

.personImage,
.card,
#aside img,
footer img {
  border-radius: 10px;
}

.personImage h5,
.card h5 {
  margin-bottom: 5vh;
}

.personImage {
  width: 70% !important;
}

.personImage img {
  width: 20vw !important;
  min-height: 35vh !important;
  border-radius: 50% !important;
  overflow: scroll;
  line-height: 4vh;
}

.personImage p,
.card p {
  width: 40%;
  margin: auto;
  padding: auto;
  margin-top: 2vh;
  min-height: 10vh;
  margin: auto;
}

.app > a,
footer a,
#containerButton a {
  text-decoration: none;
  color: white;
}

.bar1,
.bar2,
.bar3 {
  background: white;
  width: 35px;
  height: 5px;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  -moz-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  -moz-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.buttonMenu {
  width: 9vw;
  height: 5vh;
  display: none;
  padding: 0.5em;
}

.buttonMenu:hover {
  cursor: pointer;
}

#containerMain,
#aside {
  margin-top: 10vh;
}

#containerMenu {
  width: 90vw !important;
  grid-area: menu;
  margin-bottom: 10vh;
  margin: auto;
}

#containerMenu img {
  width: 100%;
  height: 50vh;
}

#containerMenu > nav {
  width: 100%;
}

#containerMain {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: nowrap;
  /* overflow: auto; */
  /* margin-top: 30vh; */
}

#containerMain div {
  margin: auto;
}

#containerMain > div img {
  height: 50vh;
  margin: auto;
}

#containerMain > div p {
  width: 80%;
  text-align: center;
  font-size: 1.1rem;
  margin: auto;
}

#containerMain button {
  width: 30% !important;
  cursor: pointer;
  padding: 1em;
  color: black;
  font-size: 0.6em;
  border: none;
}

#containerMain button:hover {
  background: black !important;
  color: white;
}

.presentacion {
  width: 100% !important;
  margin: auto;
  height: 150vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.presentacion p {
  padding: 0.5em;
  white-space: unset;
  text-overflow: ellipsis;
  text-align: justify;
  overflow: hidden;
  line-height: 5vh;
}

.presentacion h5 {
  margin-top: 2vh;
  font-size: 2em !important;
  line-height: 7vh !important;
}

.presentacion img {
  width: 15vw !important;
  height: 30vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.contact {
  color: black;
  width: 50vw !important;
  height: 105vh;
  margin: auto;
  font-size: 1.8em;
}

.contact div {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

.contact textarea {
  width: 80% !important;
  margin: auto;
  text-align: left;
  resize: none;
}

.contact input {
  border: 0px;
  text-align: left !important;
  width: 75%;
  height: 8vh;
  line-height: 8vh;
  margin-top: 5vh;
  font-size: 0.6em;
  padding: 0.2em;
  border-bottom: 2px solid #adadad;
}

#anuncios p {
  margin-top: 1vh !important;
  height: 10% !important;
  line-height: 2vh;
  width: 100% !important;
  margin: auto;
  font-size: 0.8rem;
  text-align: center;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: wrap;
}

#pub_licidad {
  position: relative;
  margin: auto;
}

#pub_licidad p {
  height: 10% !important;
  line-height: 2vh;
  width: 100% !important;
  margin: auto;
  font-size: 0.8rem;
  text-align: center;
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: wrap;
}

.left,
.right {
  z-index: 98;
  cursor: pointer;
  position: absolute;
  top: 50vh !important;
  width: auto;
  padding: 10px;
  /* margin-top: -22px;   */
  background-color: rgba(53, 52, 52, 0.8);
  color: rgba(109, 109, 109, 0.8) !important;
  font-weight: bold;
  font-size: 18px;
  transition: 0.3s ease;
  border-radius: 0 3px 3px 0;
}

.content {
  font-size: 30px;
  padding: 8px 12px;
  position: absolute;
  top: 10px;
  width: 100%;
  text-align: center;
}

#aside div:first-child {
  margin-bottom: 5vh;
}

#aside img {
  width: 100%;
  height: 35vh;
  margin: auto;
}

#aside {
  grid-area: aside;
  position: absolute;
  background: transparent;
  width: 25%;
  top: 60vh;
  right: 4vw;
}

#aside div {
  width: 100%;
  height: 40vh !important;
}

nav {
  height: 8vh !important;
  z-index: 97;
  display: flex;
  justify-content: space-around;
  align-items: center !important;
}

nav,
ul {
  z-index: 99;
  color: white;
  width: 100%;
  transition: all 0.3s;
}

nav > a {
  width: 10%;
  margin-left: 0.5em;
  font-size: 1.5rem;
  cursor: pointer;
  color: black;
  text-decoration: none;
  text-align: center;
}

nav li {
  font-size: 1.6rem;
  cursor: pointer;
  color: black;
  text-decoration: none !important;
  margin-right: 1.5rem;
}

#anuncios li,
#pub_licidad li {
  display: inline-block;
  padding: 0px !important;
  margin: 0px !important;
  width: 0.5vw !important;
  flex-grow: initial !important;
}

ul {
  display: flex !important;
  align-items: flex-end;
  overflow: hidden;
  color: black;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: all 0.2s;
  margin: 0px;
  padding: 0px;
}

ul a {
  margin-left: 1em;
  text-align: center !important;
}

.sticky {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  width: 91% !important;
  margin: 0%;
  top: 0px;
  height: 5vh;
  transition: all 0.3s;
}

.sticky li,
.sticky a {
  color: white;
}

.sticky li:hover::before,
#containerMain button {
  background: white;
  width: 100%;
  left: 0px;
}

.sticky #anuncios {
  position: fixed;
  display: block;
  width: 23vw !important;
  height: 35vh !important;
  right: 4vw;
  top: 10vh;
}

.sticky #pub_licidad {
  position: fixed;
  display: block;
  width: 23vw !important;
  height: 35vh !important;
  right: 4vw;
  bottom: 9vh;
  margin: 0px;
}

.selected {
  background: black;
  color: white;
  padding: 0.5em;
  text-align: center;
}

.sticky .selected {
  background: #d4d3d3;
  color: black;
  border: none !important;
}

li {
  font-size: 1.1rem;
  cursor: pointer;
  flex-grow: 1;
  line-height: 5vh;
  list-style: none;
  position: relative;
  text-align: center;
}

a > li::before {
  margin: 0px;
  content: "";
  position: absolute;
  width: 0%;
  bottom: 0px;
  left: 50%;
  height: 3px;
  transition: all 0.3s;
}

a > li:hover::before {
  margin: 0px;
  background: black;
  bottom: 0px;
  width: 100%;
  left: 0px;
}

footer {
  background-color: rgba(0, 0, 0) !important;
  grid-area: footer;
  /* margin-top: 5vh; */
  color: white;
  border-radius: 10px 10px 0px 0px !important;
}

footer * {
  background-color: rgba(0, 0, 0) !important;
  margin: 6vh auto;
}

footer img {
  width: 100%;
  height: 30vh;
  margin: 0px auto;
}

footer > div,
footer > div div {
  display: flex;
  justify-content: space-around;
  justify-content: center;
  align-items: center;
}

footer > div * {
  margin: 0px 2.5vw;
}

footer > div i {
  cursor: pointer;
  font-size: 1.9em !important;
}

/* #containerButton > audio {
  display: none;
} */

#live {
  background: red;
  color: white;
  width: 8vw;
  height: 5vh;
  line-height: 5vh;
  margin-left: 1em;
  padding: 0.1em;
  animation: fa-beat 1s ease infinite;
}

@media only screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .app {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas:
      "menu"
      "aside"
      "main"
      "footer";

    grid-template-columns: 100vw;
    grid-template-rows: auto auto auto;
    grid-gap: 5vh auto;
  }

  .card {
    color: black;
    padding: 1em 0px;
    width: 45%;
    margin: auto;
  }

  .card h5 {
    margin: 5vh auto !important;
  }

  .buttonMenu,
  #containerButton {
    display: block;
    position: fixed;
    top: 0px;
  }

  #containerButton {
    width: 100%;
    height: 10vh;
    line-height: 10vh;
    color: white;
    font-size: 1.4rem;
    background: rgba(0, 0, 0, 0.9);
  }

  #nightThemeButton {
    position: fixed;
    z-index: 101;
    width: 8vw !important;
    height: 5.8vh !important;
    margin-top: 2.1vh;
  }

  .personImage {
    width: 50% !important;
    margin: auto;
    height: 65vh !important;
  }

  .personImage h5 {
    margin: 0px;
  }

  .personImage img {
    width: 60% !important;
    height: 30vh !important;
    border-radius: 50% !important;
  }

  .contact {
    width: 90vw !important;
    margin-top: 10vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contact h3 {
    margin-top: 0vh !important;
  }

  .contact label {
    width: 100% !important;
    height: 10vh !important;
    line-height: 10vh;
  }

  .contact div {
    height: 40vh !important;
    line-height: 40vh !important;
  }

  .contact textarea {
    text-align: center;
    width: 90% !important;
    height: 100% !important;
    margin: auto;
  }

  .contact input,
  textarea {
    padding: 0.2em;
  }

  .right {
    color: white !important;
    top: 10vh !important;
    right: 0;
    border-radius: 3px 0 0 3px;
  }

  .left {
    padding: auto;
    margin: auto;
    color: white !important;
    top: 10vh !important;
    left: 3%;
    right: auto;
  }

  #containerMenu img {
    display: none;
  }

  #containerButton > a {
    display: inline !important;
    font-size: 0.8em;
    float: left;
    margin: auto;
    margin-left: 15vw !important;
  }

  /* #containerMenu div img{
        height: 15vh !important;
    } */

  #containerMain {
    display: grid !important;
    margin-top: 5vh;
    align-items: center;
    justify-content: center;
    /* line-height: 100vh; */
  }

  #containerMain > div {
    display: block;
    width: 60vw;
    /* line-height: 15vh; */
  }

  #containerMain > div p {
    width: 88% !important;
    /* height: 10vh !important; */
    font-size: 3.5vw !important;
    text-align: center;
    margin: auto;
  }

  #menu:checked ~ * ul {
    left: 0px !important;
  }

  nav {
    z-index: 99;
    position: fixed;
    top: 10vh;
    left: -100% !important;
  }

  ul {
    position: fixed;
    top: 10vh;
    left: -100% !important;
    height: 90vh;
    background: rgb(209, 205, 205);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 500;
  }

  .presentacion {
    display: flex !important;
    width: 80vw !important;
    margin: auto;
    height: 80vh !important;
    margin-bottom: 5vh;
  }

  .presentacion p {
    height: 100%;
    line-height: 10vh !important;
    padding: 1em;
    font-size: 100 !important;
    white-space: unset;
  }

  .presentacion img {
    width: 30vw !important;
    height: 30vh !important;
    margin: 0px;
  }

  .selected {
    width: 100vw !important;
    background: transparent;
    color: black;
    padding: 0px;
  }

  li {
    color: black;
    font-size: 1.3rem;
    width: 100%;
    height: 20vh;
    line-height: 20vh;
    flex-basis: 0px;
  }

  li:hover::before {
    display: none;
  }

  li:hover {
    color: gray;
  }

  a li {
    width: 100vw !important;
  }

  footer img {
    height: 28vh;
  }

  footer {
    width: 100vw;
    grid-area: footer;
    background: rgba(0, 0, 0, 0.9);
    /* margin-top: 5vh; */
    color: white;
  }

  footer * {
    margin: 6vh auto;
  }

  footer img {
    width: 100%;
    height: 30vh;
    margin: 0px auto;
  }

  footer > div,
  footer > div div {
    display: flex;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
  }

  audio {
    display: block;
    position: fixed !important;
    width: 28vw !important;
    top: 2vh !important;
    right: 25vw !important;
    height: 5vh;
    line-height: 5vh;
    z-index: -1;
  }

  #live {
    display: block;
    position: fixed !important;
    width: 15vw;
    top: 1.8vh !important;
    right: 5vw !important;
    height: 5vh;
    line-height: 5vh;
    z-index: -1;
    background: red;
    color: white;
    padding: 0.1em;
    animation: fa-beat 1s ease infinite;
  }

  .presentacion {
    width: 100% !important;
    height: 125vh !important;
  }

  .presentacion p {
    padding: 1.5em;
    line-height: 3vh !important;
  }

  .card {
    width: 90% !important;
    height: 78vh;
    white-space: pre-wrap;
  }

  .card p {
    margin-top: 3vh !important;
  }

  #aside {
    position: relative;
    top: 2vh !important;
    width: 100%;
    height: 80vh;
    margin-left: 0.7em;
  }

  #aside img {
    padding: 0px !important;
    width: 90% !important;
    height: 80%;
    margin: auto !important;
  }

  #anuncios p,
  #pub_licidad p {
    margin: auto;
    margin-top: 0.2vh !important;
    font-size: 0.7rem;
    text-align: center;
    text-overflow: ellipsis !important;
    overflow: hidden;
    white-space: wrap;
    width: 90% !important;
  }

  .contact {
    width: 90vw !important;
    height: 110vh;
    margin: auto;
  }
}

/* Keyframes */
@keyframes fill {
  0% {
    width: 0%;
    height: 1px;
  }
  50% {
    width: 100%;
    height: 1px;
  }
  100% {
    width: 100%;
    height: 100%;
    background: #2d2924;
  }
}

@keyframes fa-beat {
  0% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(0.8);
  }
}
